import React, { useState, useMemo } from "react";
import styles from "./style.module.css";
import withData from "./with-data";

// -- Libs
import {
  tileFillColorFromScore,
  wait
} from "../../lib/helpers";
import { EVENT_FOCUS } from "../../lib/constants";

// -- Components
import { Link } from "react-router-dom";
import Timeline from "../Timeline";
import StatusChart from "../StatusChart";

// -- Hooks
import useLanguage from "../../hooks/use_language";
import useTimeSlices from "../../hooks/use_timeslices";

const RelationshipStatus = (props = {}) => {
  const {
    countryTag = "",
    timeline = [],
    statusHistory = []
  } = props;

  const {
    timeSlices,
    timeSliceIndex,
    setTimeSliceIndex,
    minDate,
    maxDate
  } = useTimeSlices([...timeline, ...statusHistory])


  // -- State
  const i18n = useLanguage(),
    [focusedEvent, setFocusedEvent] = useState(null);

  const latestScore = useMemo(() => {
    const lastStatus = statusHistory.sort((a, b) => a.date - b.date)[statusHistory.length - 1],
      { value = 5 } = lastStatus;
    return value.toFixed(1)
  }, [statusHistory])

  return (
    <section className={styles.root}>
      <div className={styles.graph}>
        <div className={styles.header}>
          <h2>
            {i18n("relationship_status", "graph_header")}
            <Link to="/page/methodology">
              <i className="material-icons">help_outline</i>
            </Link>
          </h2>
          <span
            className={styles.score}
            style={{ background: tileFillColorFromScore(latestScore) }}
          >
            {latestScore}
          </span>
        </div>
        <figure>
          <StatusChart
            sentimentHistory={statusHistory}
            events={timeline}
            height={310}
            width={420}
            eventFocusEvent={EVENT_FOCUS}
            minDate={minDate}
            maxDate={maxDate}
            expandScope={expandScope}
            canExpandScope={canExpandScope()}
            contractScope={contractScope}
            canContractScope={canContractScope()}
            zoomLevel={
              (timeSlices.length - timeSliceIndex) / timeSlices.length
            }
            focusedEvent={focusedEvent}
            delay={400}
            step={timeSliceIndex}
          />
        </figure>
      </div>
      {timeline.error || (
        <Timeline
          className={styles.timelineContainer}
          tag={countryTag}
          events={timeline}
          eventFocusEvent={EVENT_FOCUS}
          minDate={minDate}
          canExpandScope={canExpandScope()}
          expandScope={expandScope}
          setEventFocus={setEventFocus}
        >
          <div className={styles.header}>
            <h2>{i18n("relationship_status", "timeline_header")}</h2>
          </div>
        </Timeline>
      )}
    </section>
  );

  function expandScope() {
    canExpandScope() && setTimeSliceIndex(timeSliceIndex + 1);
  }

  function contractScope() {
    canContractScope() && setTimeSliceIndex(timeSliceIndex - 1);
  }

  function canExpandScope() {
    return timeSliceIndex < timeSlices.length - 1;
  }
  function canContractScope() {
    return timeSliceIndex > 0;
  }

  async function setEventFocus(date) {
    if (minDate > date && canExpandScope()) {
      wait(200);
      expandScope();
      wait(400);
      setFocusedEvent(date);
    } else {
      wait(200);
      setFocusedEvent(date);
    }
  }
}

export default withData(RelationshipStatus);
